import { useHasMounted } from "../hooks";

const SchemaWrapper = ({ children }: any) => {
  const mounted = useHasMounted();

  if (!mounted) return children;

  return null;
};

export default SchemaWrapper;
