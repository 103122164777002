import { useSelector } from "react-redux";
import Head from "next/head";
import SchemaWrapper from "../../shared/components/SchemaWrapper";

import { faqQuestions } from "../../components/FAQ/FaqList";
import { testimonialSchemaItems } from "../../helpers";
import { shouldRedirectToNewFlow } from "../../shared/utils/helper.utils";

const getTestimonialItems = testimonialSchemaItems.map((item) => ({
  "@type": "Review",
  author: { "@type": "Person", name: item?.name },
  reviewBody: item?.feedback,
  reviewRating: { "@type": "Rating", ratingValue: "5" },
}));

const DocHead = () => {
  const utm = useSelector((state: any) => state.utmReducer);
  const newFlow = shouldRedirectToNewFlow(utm);

  const getFaqContent = faqQuestions(newFlow, false, false).map((item) => ({
    "@type": "Question",
    name: item.title,
    acceptedAnswer: {
      "@type": "Answer",
      text: item.content,
    },
  }));

  return (
    <Head>
      <title>
        Buy Preventive Health Care Plans Online, Healthcare India - Kenko
      </title>

      <meta property="og:type" content="website" />
      <meta
        name="title"
        property="title"
        content="Buy Preventive Health Care Plans Online, Healthcare India - Kenko"
      />
      <meta
        name="description"
        property="description"
        content="Get a comprehensive health care plan from Kenko, India’s leading healthcare provider, and enjoy discounts on doctor fees, dental implants & medicines. Buy online."
      />
      <meta
        name="og:title"
        property="og:title"
        content="Buy Preventive Health Care Plans Online, Healthcare India - Kenko"
      />
      <meta
        name="og:image"
        property="og:image"
        content="https://kenko-ui-assets.s3.ap-south-1.amazonaws.com/favicon/Kenko+Favicon_300x300-13.png"
      />
      <meta
        name="og:description"
        property="og:description"
        content="Get a comprehensive health care plan from Kenko, India’s leading healthcare provider, and enjoy discounts on doctor fees, dental implants & medicines. Buy online."
      />
      <meta name="twitter:card" content="summary" />
      <meta
        name="twitter:title"
        content="Get a comprehensive health care plan from Kenko, India’s leading healthcare provider, and enjoy discounts on doctor fees, dental implants & medicines. Buy online."
      />
      <meta
        name="twitter:description"
        content="Smarter healthcare plans with wider coverage than insurance plans at super-affordable rates"
      />
      <meta
        name="keywords"
        content="Healthcare India, Preventive Health Care, Health Care Plans, Healthcare Online"
      />

      <link rel="canonical" href="https://kenkohealth.in/" />

      <SchemaWrapper>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `[{
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "KENKO",
              "url": "https://kenkohealth.in/",
              "description": "Get a comprehensive health care plan from Kenko, India’s leading healthcare provider, and enjoy discounts on doctor fees, dental implants &amp; medicines. Buy online.",
              "logo": "https://kenkohealth.in/images/Logo.svg",
              "foundingDate": "2017",
              "founders": [{
                "@type": "Person"
                ,"name": "Dhiraj Goel"
              },
              {
                "@type": "Person",
                "name": "Aniruddha Sen"
              }],
              "address": 
                {
                  "@type": "PostalAddress",
                  "streetAddress": "Bank of America Corporate Office",
                  "addressLocality": "16 Floor, 1608 B & C Wing, One BKC C/66 G Block, JIO Trade Centre Road",
                  "addressRegion": "Bandra East, Maharashtra",
                  "postalCode": "400051",
                  "addressCountry": "India"
                },
                "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "customer support",
                  "telephone": "+91 9113839585",
                  "email": "care@kenko-health.in"
                },
                "sameAs": ["https://www.facebook.com/kenkohealth.in/", "https://twitter.com/yourkenkohealth", "https://www.instagram.com/kenkohealth.in/", "https://www.linkedin.com/company/kenko-health/", "https://www.youtube.com/channel/UCSR1JJaOegXRExyBLChzggg"]},
                {
                  "@context": "https://schema.org",
                  "@type": "FAQpage",
                  "mainEntity": ${JSON.stringify(getFaqContent)},
            {
              "@context": "http://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "url": "https://www.blog.kenkohealth.in/post/hungry-kya-snack-on-these-healthy-winter-munchies"},
                "headline": "Hungry Kya? Snack On These Healthy Winter Munchies",
                "image": {
                  "@type": "ImageObject",
                  "url": "https://kenkohealth.in/images/blog_section/Winter%20Snacks.webp"
                },
                "author": {
                  "@type": "Person",
                  "name": "Kenko Health"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "Kenko Health"
                },
                "articleBody": "Hungry Kya? Snack On These Healthy Winter Munchies"
              },
              {
                "@context": "http://schema.org",
                "@type": "BlogPosting",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "url": "https://www.blog.kenkohealth.in/post/never-walk-alone-gadgets-to-guide-your-fitness-journey"},
                  "headline": "Never Walk Alone! Gadgets To Guide Your Fitness Journey",
                  "image": {
                    "@type": "ImageObject",
                    "url": "https://kenkohealth.in/images/blog_section/Fitness.webp"
                  },
                  "author": {
                    "@type": "Person",
                    "name": "Kenko Health"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "Kenko Health"
                  },
                  "articleBody": "Never Walk Alone! Gadgets To Guide Your Fitness Journey"
                },
                {
                  "@context": "http://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "url": "https://www.blog.kenkohealth.in/post/wealth-spent-on-health-the-indian-edition"},
                    "headline": "Wealth Spent On Health: The Indian Edition",
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://kenkohealth.in/images/blog_section/Wealth.webp"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Kenko Health"
                    },
                    "publisher": {
                      "@type": "Organization",
                      "name": "Kenko Health"
                    },
                    "articleBody": "Wealth Spent On Health: The Indian Edition"
                  },{
                    "@context": "http://schema.org",
                    "@type": "BlogPosting",
                    "mainEntityOfPage": {
                      "@type": "WebPage",
                      "url": "https://www.blog.kenkohealth.in/post/we-don-t-talk-enough-about-men-s-sexual-health"
                    },
                    "headline": "We Don't Talk Enough About Men's Sexual Health!",
                    "image": {
                      "@type": "ImageObject",
                      "url": "https://kenkohealth.in/images/blog_section/Sexual%20Health.webp"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "Kenko Health"
                    },"publisher": {
                      "@type": "Organization",
                      "name": "Kenko Health"
                    },
                    "articleBody": "We Don't Talk Enough About Men's Sexual Health!"
                  },{
                    "@context": "https://schema.org",
                    "@type": "ItemList",
                    "itemListElement": [{
                      "@type": "VideoObject",
                      "position": "1",
                      "name": "What You See Is What You Get...",
                      "description": "What You See Is What You Get...",
                      "thumbnailUrl": "https://i.ytimg.com/vi/d2zJ5t2e5n0/hqdefault.jpg",
                      "uploadDate": "2022-08-10T12:31",
                      "duration": "PT40S",
                      "embedUrl": "https://youtu.be/d2zJ5t2e5n0"
                    }]
                    },
                    {
                      "@context": "https://schema.org/",
                      "@type": "Product",
                      "brand": {"@type": "Brand","name": "Kenko Health"},
                      "description": "Happy subscribers. Happy reviews.",
                      "name": "Fam Speak",
                      "review":  ${JSON.stringify(getTestimonialItems)},
                      "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "5",
                        "bestRating": "5",
                        "ratingCount": "11"
                      }
                    },{
                      "@context": "http://schema.org/",
                      "@type": "Service",
                      "serviceType": "Meet Our Plans",
                      "url": "https://kenkohealth.in/plans",
                      "description": "Our health plans cover all your large and small medical expenses",
                      "provider": {
                        "@type": "LocalBusiness",
                        "name": " Kenko Health",
                        "image": "https://kenkohealth.in/images/Logo.svg",
                        "telephone": "1800-203-5314"
                      },
                      "areaServed": {
                        "@type": "Country","name": "India"
                      },
                      "hasOfferCatalog": {
                        "@type": "OfferCatalog",
                        "name": "Kenko Plans",
                        "url": "https://kenkohealth.in/plans",
                        "itemListElement": [{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Family OPD Standard Plan",
                            "url": "https://kenkohealth.in/plan/Family-OPD-Standard-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Personal OPD Prime Plan",
                            "url": "https://kenkohealth.in/plan/Personal-OPD-Prime-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Senior Citizens Plan",
                            "url": "https://kenkohealth.in/plan/Senior-Citizens-Plan"
                          }
                        },
                        {
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Personal OPD Plan",
                            "url": "https://kenkohealth.in/plan/Personal-OPD-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Family OPD Prime Plan",
                            "url": "https://kenkohealth.in/plan/Family-OPD-Prime-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Personal OPD Standard Plan",
                            "url": "https://kenkohealth.in/plan/Personal-OPD-Standard-Plan"
                          }
                        },
                        {
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Personal Plan",
                            "url": "https://kenkohealth.in/plan/Personal-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Diabetic Plan",
                            "url": "https://kenkohealth.in/plan/Diabetic-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "The Family Plan",
                            "url": "https://kenkohealth.in/plan/The-Family-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Starter Plan",
                            "url": "https://kenkohealth.in/plan/Starter-Plan"
                          }
                        },{
                          "@type": "Offer",
                          "itemOffered": {
                            "@type": "Service",
                            "name": "Family OPD Plus Plan",
                            "url": "https://kenkohealth.in/plan/Family-OPD-Plus-Plan"
                          }
                        }
                      ]
                    }
                  }
                ]`,
          }}
        ></script>
      </SchemaWrapper>

      <link
        rel="preload"
        as="image"
        href="/images/hero_section/hero_image_mobile.webp"
      ></link>
    </Head>
  );
};

export default DocHead;
